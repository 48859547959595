import React from 'react';
import '../styles/herobanner.css';

function Herobanner () {
    return(
        <div className="hero">
            <div className="hero_container text-center">
                {/* <h1>
                Hello I’m Jane. Welcome to my portfolio.
                </h1> */}
                <h1 className="mt-10  animate-fade">
               Front-End Developer
                </h1>
                <h1 className='animate-fade'>
                with a UI/UX Foundation
                </h1>
                <p className='animate-fade'>
                Crafting Frontends That Connect✨‍                
                 </p>
            </div>
        </div>
    )
}

export default Herobanner