
import React from 'react';
import image1 from '../images/whittier_logo/WHITTIER DISTRIC LOGO_outlined-01.jpg';
import image2 from '../images/whittier_logo/WHITTIER DISTRIC LOGO_outlined-02.jpg';
import image3 from '../images/whittier_logo/WHITTIER DISTRIC LOGO_outlined-03.jpg';
import image4 from '../images/whittier_logo/WHITTIER DISTRIC LOGO_outlined-04.jpg';
import image5 from '../images/whittier_logo/WHITTIER DISTRIC LOGO_outlined-05.jpg';
import image6 from '../images/whittier_logo/WHITTIER DISTRIC LOGO_outlined-06.jpg';
import image7 from '../images/whittier_logo/WHITTIER DISTRIC LOGO_outlined-07.jpg';

const PostWhittierLogo = () => {
    return (
        <div className="post_body design_posts">
        <div className="post_container max-w-[1200px] w-full m-auto">
            <img src={image1} alt="content" className="text-center m-auto"/>
            <img src={image2} alt="content" className="text-center m-auto"/>
            <img src={image3} alt="content" className="text-center m-auto"/>
            <img src={image4} alt="content" className="text-center m-auto"/>
            <img src={image5} alt="content" className="text-center m-auto"/>
            <img src={image6} alt="content" className="text-center m-auto"/>
            <img src={image7} alt="content" className="text-center m-auto"/>

            </div>
        </div>
    )
}

export default PostWhittierLogo