
import React from 'react';
import image1 from '../images/lv_booth/2017 LV BOOTH EXPLANATION-01.jpg';
import image2 from '../images/lv_booth/2017 LV BOOTH EXPLANATION-02.jpg';
import image3 from '../images/lv_booth/2017 LV BOOTH EXPLANATION-03.jpg';
import image4 from '../images/lv_booth/2017 LV BOOTH EXPLANATION-04.jpg';
import image5 from '../images/lv_booth/2017 LV BOOTH EXPLANATION-05.jpg';
import image6 from '../images/lv_booth/2017 LV BOOTH EXPLANATION-06.jpg';
import image7 from '../images/lv_booth/2017 LV BOOTH EXPLANATION-07.jpg';
import content1 from '../images/lv_booth/2017 LV SHOW BOOTH_PT-01.jpg';
import content2 from '../images/lv_booth/2017 LV BOOTH EXPLANATION-MOOD BOARD-01.jpg';
import content3 from '../images/lv_booth/2017 LV BOOTH EXPLANATION-ACTUAL-01.jpg';
import content4 from '../images/lv_booth/other design2-01.jpg';
import content5 from '../images/lv_booth/2017 LV CATALOGUE-01.jpg';
import footer from '../images/lv_booth/2017 LV BOOTH LAST BOARD-01.jpg';


const PostLvBooth = () => {
    return (
        <div className="post_body design_posts">
        <div className="post_container max-w-[1200px] w-full m-auto">
            <img src={image1} alt="content" className="text-center m-auto"/>
            
            <img src={content1} alt="content" className="text-center m-auto"/>
            <img src={content2} alt="content" className="text-center m-auto"/>
            <img src={content3} alt="content" className="text-center m-auto"/>
            <img src={image2} alt="content" className="text-center m-auto"/>

            <img src={image3} alt="content" className="text-center m-auto"/>
            <img src={image4} alt="content" className="text-center m-auto"/>
            <img src={image5} alt="content" className="text-center m-auto"/>
            <img src={image6} alt="content" className="text-center m-auto"/>
            <img src={image7} alt="content" className="text-center m-auto"/>

            <img src={content4} alt="content" className="text-center m-auto"/>
            <img src={content5} alt="content" className="text-center m-auto"/>

            <img src={footer} alt="content" className="text-center m-auto"/>
            </div>
        </div>
    )
}

export default PostLvBooth