
import React from 'react';
import image1 from '../images/zury_passion/2017 DEC ZURY PASSION-01.jpg';
import image2 from '../images/zury_passion/2017 DEC ZURY PASSION-02.jpg';
import image3 from '../images/zury_passion/2017 DEC ZURY PASSION-03.jpg';
import image4 from '../images/zury_passion/2017 DEC ZURY PASSION-04.jpg';
import image5 from '../images/zury_passion/2017 DEC ZURY PASSION-05.jpg';
import image6 from '../images/zury_passion/2017 DEC ZURY PASSION-06.jpg';

const PostZuryPassion = () => {
    return (
        <div className="post_body design_posts">
        <div className="post_container max-w-[1200px] w-full m-auto">
            <img src={image1} alt="content" className="text-center m-auto"/>
            <img src={image2} alt="content" className="text-center m-auto"/>
            <img src={image3} alt="content" className="text-center m-auto"/>
            <img src={image4} alt="content" className="text-center m-auto"/>
            <img src={image5} alt="content" className="text-center m-auto"/>
            <img src={image6} alt="content" className="text-center m-auto"/>
            </div>
        </div>
    )
}

export default PostZuryPassion