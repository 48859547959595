
import React from 'react';
import image from '../images/wiggle_loc/WIGGLE EX_OL-01.jpg';

const PostWiggleLoc = () => {
    return (
        <div className="post_body design_posts">
        <div className="post_container">
            <img src={image} alt="content" className="text-center m-auto"/>
            </div>
        </div>
    )
}

export default PostWiggleLoc