
import React from 'react';
import Card from '../components/Card'
import whittier_logo from '../images/whittier_logo/Whittier District logo_600.jpg';
import zury_passion from '../images/zury_passion/DEC ZURY PASSION-01.jpg';
import five_step_chef from '../images/5step_chef/thumbnail1.gif';
import lv_booth from '../images/lv_booth/LVBOOTH-600.jpg';
import thumb_rebrand from '../images/rebrand/tray.png';
import soup from '../images/fnl_prints/soupbag.png';


import wiggle_loc from '../images/wiggle_loc/WG POSTER-01.jpg';


import { NavLink } from 'react-router-dom';

class MenuDesign extends React.Component {
    render() {
        return (
            <div className="body px-0 py-[60px]">
            <h1>Design Projects</h1>
            <div className="body_container">
            <NavLink to="/post-rebrand"><Card preheader="DESIGN, UI/UX" image={thumb_rebrand} title="Rebrand Fresh N' Lean" description="Revamping Corporate Identity and Redesigning Packaging"/></NavLink>
            <NavLink to="/post-fnl-prints"><Card preheader="DESIGN" image={soup} title="Fresh N' Lean Print Designs" description="Creating packaging designs and window graphics"/></NavLink>
            <a href="https://janenoh-design.com/5step-chef/"><Card preheader="DESIGN" image={five_step_chef} title="5 Step Chef" description="Creating animation explaining 5 step recipe"/></a>
            <NavLink to="/post-wiggleloc"><Card preheader="DESIGN" image={wiggle_loc} title="Wiggle Loc Poster" description="Designing marketing poster for new hair product"/></NavLink>
            <NavLink to="/post-zury-passion"><Card preheader="DESIGN" image={zury_passion} title="Zury Passion Catalogue" description="Designing monthly catagolue promoting new hair products"/></NavLink>
            <NavLink to="/post-lv-booth"><Card preheader="DESIGN" image={lv_booth} title="Las Vegas Booth" description="Minimizing Drop-off Rates by Enhancing User Flow"/></NavLink>
            <NavLink to="/post-whittier-logo"><Card preheader="DESIGN" image={whittier_logo} title="Whittier Logo Design" description="Designing Logo for Uptown Whittier District"/></NavLink>

            </div>
            </div>
        )

    }
}

export default MenuDesign